<script>
import { api } from "@/state/services";

export default {
  components: {},
  data() {
    return {
      chosen: [],
      progress: [],
      type: {
        id: null,
        name: null,
        discount: null,
      },
      types: null,
      choose: {
        type: null,
        modal: false,
      },
    };
  },
  methods: {
    getConfig() {
      api
        .get('store/config')
        .then((response) => {
          if (response.data.status=='success') {
            this.type = response.data.store.type
            this.progress = response.data.store.progress,
            this.types = response.data.store.types
            this.choose.type = this.type.id
          }
        })
    },
    setChange() {
      api
        .post('store/change', {
          type: this.choose.type
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.choose.modal = false

            localStorage.removeItem('cart');
            this.$parent.getCart()
            this.getConfig()

            if (this.$parent.$parent.getProducts()) {
              this.$parent.$parent.getProducts()
            }
          }
        })
    },
    getPercent(from, to) {
      var total = this.total
      total = total.replace('R$ ', '');
      total = total.replace(',', '.');

      if ((total - from) < 0) {
        return 0;
      }

      if (((total - from) * 100) / (to - from) <= 100) {
        return (((total - from) * 100) / (to - from)).toFixed(0)
      } else {
        return 100
      }
    }
  },
  mounted() {
    this.getConfig()
  },
  props: {
    total: {
      default: '',
    }
  },
}
</script>

<template>
  <div class="mb-4">
    <div v-if="type.id" class="card rounded-0 mb-0" style="margin-left: -23px; margin-right: -23px;">
      <div class="card-body py-2">
        <div class="d-flex align-items-center justify-content-between">
          <p class="text-left m-0">Você está na loja de <b>{{ type.name }}</b></p>
          <button class="btn btn-success btn-sm float-right m-1 text-uppercase" @click="choose.modal = true">Alterar</button>
        </div>
      </div>
    </div>
    <b-modal v-model="choose.modal" title="Lojas" hide-footer centered>
      <div class="form-group">
        <form @submit.prevent="setChange">
          <label for="exampleFormControlSelect1">Selecione a loja</label>
          <select v-model="choose.type" class="form-control" id="exampleFormControlSelect1">
            <option v-for="(type, index) in types" :value="type.id" :key="index">{{ type.name }}</option>
          </select>
          <button class="btn btn-success text-uppercase mt-2" type="submit">Alterar</button>
        </form>
      </div>
    </b-modal>
    <div v-if="progress && progress.length > 0" class="card rounded-0 mt-1 mb-0" style="margin-left: -23px; margin-right: -23px;">
      <div class="card-body py-3">
        <div class="d-flex">
          <div class="flex-fill" v-for="(row, index) in progress" :key="index">
            <div class="progress rounded-0" style="height: 25px">
              <div clas="progress-bar progress-bar-animated" :class="row.color" role="progressbar" :style="'height: 25px; width:' + getPercent(row.value.from, row.value.to) + '%'" :aria-valuenow="getPercent(row.value.from, row.value.to)" :aria-valuemin="0" :aria-valuemax="100"></div>
            </div>
            <div class="d-flex justify-content-between font-size-12 pt-1">
              <div class="px-1">{{ row.name }}</div>
              <div class="px-1">{{ getPercent(row.value.from, row.value.to) }}%</div>
              <div>{{ row.value.to }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>